import { messageError, translation } from '@/utils';
import { ProductionOrderResource, ProductionOrderProductResource, ProjectProductList } from '@/resource/model';
import { Component, Watch, Prop, Vue } from 'vue-property-decorator';
import OsTable, { OsTableColumn, OsTableOption } from '@/components/os-table/os-table';
import { OperationOption } from '@/components/os-table-operation/os-table-operation';
import { SelectProjectProduct } from '@/views/dialogs';
import { Message } from 'element-ui';
import { productionOrderProductService, productionProductSearchService } from '@/api';
@Component({
  components: {
    SelectProjectProduct
  }
})
export default class ProductionOrderContent extends Vue {
  @Prop({
    required: true
  })
  public order!: ProductionOrderResource;

  /**
   * 项目产品选择弹窗显示控制
   */
  public productDialogVisible = false;

  /**
   * 下发生产的产品数据集合
   */
  public productList = [];

  /**
   * 项次ID集合
   */
  public projectItemIdList: Array<number> = [];

  /**
   * 编辑行
   */
  public editRow: ProductionOrderProductResource | null = null;

  /**
   * 表格配置项
   */
  public tableOption: OsTableOption<ProductionOrderProductResource> = {
    loading: false,
    data: [],
    fit: true,
    size: 'mini',
    border: true
  };

  /**
   * 表格工具栏配置
   */
  public tableToolbarOptions: Array<OperationOption> = [
    {
      operationType: 'allProduction',
      slot: 'start',
      label: 'order.allProduction',
      type: 'primary',
      icon: 'el-icon-plus',
      loading: false,
      disabled: !this.order.supplierId,
      permissionCode: '',
      handleClick: (): void => {
        this.listAllProjectProductDetails();
      }
    },
    {
      operationType: 'selectProduct',
      slot: 'start',
      label: 'order.selectProduct',
      type: 'primary',
      disabled: !this.order.supplierId,
      icon: 'el-icon-plus',
      permissionCode: '',
      handleClick: (): void => {
        this.openProductSelectDialog();
      }
    },
    {
      operationType: 'delete',
      slot: 'start',
      label: 'button.delete',
      type: 'danger',
      icon: 'el-icon-delete',
      plain: true,
      disabled: true,
      permissionCode: '',
      handleClick: (): void => {
        this.batchDelete();
      }
    }
  ];

  /**
   * 表格列配置
   */
  public tableColumnOptions: Array<OsTableColumn<ProductionOrderProductResource>> = [
    {
      type: 'selection',
      prop: 'id',
      label: '',
      reserveSelection: true,
      fixed: true,
      align: 'center'
    },
    {
      prop: 'itemCode',
      label: 'orderProduct.itemCode',
      minWidth: '180px',
      showOverflowTooltip: true,
      fixed: true
    },
    {
      prop: 'pointName',
      label: 'orderProduct.pointName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'customerProductName',
      label: 'orderProduct.customProductName',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'platformProductName',
      label: 'orderProduct.platformProductName',
      minWidth: '220px',
      showOverflowTooltip: true
    },
    {
      prop: 'backendCrafts',
      label: 'orderProduct.backendCrafts',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'count',
      label: 'orderProduct.count',
      minWidth: '120px',
      showOverflowTooltip: true
    },
    {
      prop: 'visibleWidth',
      label: 'orderProduct.visibleSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'finishWidth',
      label: 'orderProduct.completeSize',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressPicture',
      label: 'orderProduct.prepressPicture',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'prepressRemark',
      label: 'orderProduct.prepressDescription',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'remark',
      label: 'orderProduct.remark',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredDeliveryTime',
      label: 'orderProduct.requiredDeliveryTime',
      minWidth: '180px',
      showOverflowTooltip: true
    },
    {
      prop: 'requiredArriveTime',
      label: 'orderProduct.requiredArriveTime',
      minWidth: '180px',
      showOverflowTooltip: true
    }
  ];

  /**
   * 选中数据缓存对象
   */
  private selectedRows: Array<ProductionOrderProductResource> = [];

  public created(): void {
    if (this.order.id) {
      this.$nextTick(async () => {
        this.tableOption.data = await productionOrderProductService.listAll(this.order.id);
      });
    }
  }

  /**
   * 表格行选中数据监听
   */
  @Watch('selectedRows')
  public handleSelectionChanged(value: Array<ProductionOrderResource>): void {
    this.tableToolbarOptions.forEach(option => {
      if (option.operationType === 'delete') {
        option.disabled = value.length === 0;
      }
    });
  }

  /**
   * 表格行选中事件
   */
  public handleSelectionChange(selectedData: Array<ProductionOrderProductResource>): void {
    this.selectedRows = selectedData;
  }

  public goBack(): void {
    this.$emit('back', true);
  }

  /**
   * 提交事件
   */
  public handleSubmit(): void {
    const result = this.checkSubmitData();
    if (result) {
      this.$emit('submit', this.getProjectItemsRes());
    }
  }

  /**
   * 提交并派单事件
   */
  public handleSubmitAndSend(): void {
    const result = this.checkSubmitData();
    if (result) {
      this.$emit('submit-send', this.getProjectItemsRes());
    }
  }

  /**
   * 校验数据
   * @returns 校验结果
   */
  public checkSubmitData(): boolean {
    // 检查是否分配产品
    if (this.tableOption.data.length <= 0) {
      Message.warning(translation('order.pleaseDistributeProduct'));
      return false;
    }

    return true;
  }

  /**
   * 产品选择回调
   */
  public handleProductSelected(selectedRows: Array<ProjectProductList>): void {
    this.convertToOrderProduct(selectedRows);
  }

  private openProductSelectDialog(): void {
    this.productDialogVisible = true;
  }

  /**
   * 将选择的项目产品转换成订单产品
   */
  private convertToOrderProduct(selectedRows: Array<ProjectProductList>): void {
    selectedRows.forEach(item => {
      const obj: ProductionOrderProductResource = {
        ...item,
        code: '',
        supplierProductId: 0,
        supplierProductName: '',
        totalArea: 0,
        printingStatus: 0,
        backendStatus: 0,
        status: 0,
        finishTime: '',
        processType: 0,
        processStatus: 0,
        backendCraftsList: [],
        prepressFlag: 0,
        printingFlag: 0,
        backendFlag: 0,
        orderItemId: 0,
        orderItemCode: '',
        prepressConfirmFile: '',
        prepressConfirmFileFullUrl: '',
        prepressCompleteFile: '',
        progressLogList: [],
        salePrice: 0,
        priceUnit: 0,
        priceUnitName: '',
        createTime: '',
        createUser: 0,
        deleteFlag: 0,
        updateTime: '',
        updateUser: 0,
        version: '',
        platformProductId: 0,
        customerProductId: 0,
        urgentOrderFlag: 0,
        areaPriceFlag: 1
      };
      this.tableOption.data.push(obj);
    });
  }

  /**
   * 批量删除
   */
  private batchDelete(): void {
    this.tableOption.data = this.tableOption.data.filter(
      x => this.selectedRows.findIndex(y => y.itemCode === x.itemCode) === -1
    );
    (this.$refs.tableRef as OsTable).clearSelection();
    this.selectedRows = [];
  }

  /**
   * 一键导入
   * 获取项目所有产品明细数据
   */
  private listAllProjectProductDetails(): void {
    const option: any = this.tableToolbarOptions.find(item => item.operationType === 'allProduction');

    option.loading = true;

    // 查询参数
    const queryForm = {
      id: this.order.id,
      projectId: this.order.projectId,
      supplierId: this.order.supplierId,
      projectItemIdList: this.projectItemIdList
    };

    productionProductSearchService
      .getList(queryForm as any)
      .then(res => {
        if (res.data.length > 0) {
          this.convertToOrderProduct(res.data);
        }
      })
      .catch(error => {
        messageError(error);
      })
      .finally(() => {
        option.loading = false;
      });
  }

  private getProjectItemsRes(): Array<number> {
    return this.tableOption.data.map(x => x.itemId || x.orderItemId);
  }

  /**
   * 表格数据监听
   */
  @Watch('tableOption.data')
  private getProjectItemIdsList(value: Array<ProductionOrderProductResource>): void {
    (this.projectItemIdList as Array<number>) = value.map(x => x.itemId || x.orderItemId);
  }

  @Watch('order.supplierId')
  private handleSupplierIdChange(value: number): void {
    const allProductionBtn = this.tableToolbarOptions.find(x => x.operationType === 'allProduction')!;
    const selectProjectItemBtn = this.tableToolbarOptions.find(x => x.operationType === 'selectProduct')!;
    if (value) {
      selectProjectItemBtn.disabled = allProductionBtn.disabled = false;
    } else {
      selectProjectItemBtn.disabled = allProductionBtn!.disabled = true;
    }
  }
}
