import { dateFormat, messageError, messageErrors, translation } from '@/utils';
import { productionOrderService, supplierService } from '@/api';
import { Component, Vue } from 'vue-property-decorator';
import { ProductionOrderResource, SupplierResource } from '@/resource/model';
import ProductionOrderContent from './production-order-content/production-order-content.vue';
import { OsFileUploadOptions } from '@/components/os-file-upload/os-file-upload';
import { HttpRequestOptions } from 'element-ui/types/upload';
import minioService, { MinIOFile } from '@/api/minio';
import { ElForm } from 'element-ui/types/form';
import { cloneDeep } from 'lodash-es';
import { TagsViewModule } from '@/store/modules/tags-view';
@Component({
  components: {
    ProductionOrderContent
  }
})
export default class AddProductionOrder extends Vue {
  public pageLoading = false;

  /**
   * 是否新建
   */
  public isNew = true;

  public defaultForm: Partial<ProductionOrderResource> = {
    projectId: Number(this.$route.query.projectId),
    supplierId: undefined,
    supplierName: '',
    remark: '',
    packRequire: '',
    detailFile: '',
    makeFile: '',
    projectItemIdList: [],
    type: 1,
    id: Number(this.$route.query.id),
    orderId: Number(this.$route.query.id)
  };
  /**
   * 订单信息
   */
  public productionOrderForm: Partial<ProductionOrderResource> = cloneDeep(this.defaultForm);

  public formRules = {
    supplierId: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value) {
            callback(new Error(translation('order.selectSupplier')));
            return;
          }
          callback();
        }
      }
    ],
    detailFile: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value && this.productionOrderForm.type === 2) {
            callback(new Error(translation('order.pleaseUploadFile')));
            return;
          }
          callback();
        }
      }
    ],
    makeFile: [
      {
        required: true,
        validator: (rule: any, value: string, callback: Function): void => {
          if (!value && this.productionOrderForm.type === 2) {
            callback(new Error(translation('order.pleaseUploadFile')));
            return;
          }
          callback();
        }
      }
    ]
  };

  public detailUploadOptions: OsFileUploadOptions = {
    maxSize: 20,
    accept: '.xlsx, .xls',
    autoUpload: true,
    limit: 1,
    multiple: false,
    drag: true,
    action: '',
    uploadLoading: false,
    httpRequest: this.uploadDetailFile
  };

  public productionUploadOptions: OsFileUploadOptions = {
    maxSize: 2048,
    accept: '.zip',
    autoUpload: true,
    limit: 1,
    multiple: false,
    drag: true,
    action: '',
    uploadLoading: false,
    httpRequest: this.uploadProductionFile
  };

  public suppliers: Array<SupplierResource> = [];

  public addSuccess = false;

  public created(): void {
    if (Number(this.$route.query.id)) {
      this.isNew = false;
      this.loadOrderData();
    } else {
      this.isNew = true;
      this.initSuppliers();
    }
  }

  /**
   * 退出事件
   */
  public goBack(allow: boolean): void {
    this.$router.go(-1);
    if (allow) {
      TagsViewModule.DelView(this.$route);
    }
  }

  /**
   * 线下订单提交
   */
  public handleOfflineSubmit(submitWay: 'submit' | 'submitAndSend'): void {
    this.productionOrderForm.projectItemIdList = [];
    this.submit(submitWay);
  }

  /**
   *线上订单提交
   */
  public handleOnlineSubmit(projectItemIds: Array<number>, submitWay: 'submit' | 'submitAndSend'): void {
    this.productionOrderForm.projectItemIdList = projectItemIds || [];
    this.submit(submitWay);
  }

  /**
   * 去订单列表
   */
  public goOrderList(): void {
    TagsViewModule.DelView(this.$route);
    this.$router.push('/production-order');
  }

  /**
   * 再次创建
   */
  public handleCreateAgain(): void {
    this.addSuccess = false;
    this.productionOrderForm = cloneDeep(this.defaultForm);
  }

  /**
   * 供应商变化清空列表
   */
  public changeSupplierId(): void {
    (<ProductionOrderContent>this.$refs.productionOrderConcentRef).tableOption.data = [];
  }

  private async loadOrderData(): Promise<void> {
    this.pageLoading = true;
    try {
      const res: ProductionOrderResource = await productionOrderService.getById(this.productionOrderForm.id!);
      this.productionOrderForm.supplierName = res.supplierName;
      this.productionOrderForm.remark = res.remark;
      this.productionOrderForm.packRequire = res.packRequire;

      this.productionOrderForm.type = res.type || 1;
      this.productionOrderForm.projectId = Number(res.projectId || 0);
      this.productionOrderForm.supplierId = Number(res.supplierId || 1);
      if (res.type === 2) {
        // 线下订单，显示文件信息
        this.productionOrderForm.detailFile = res.detailFile;
        this.productionOrderForm.makeFile = res.makeFile;
      }
    } catch (error) {
      messageError(<Error>error);
    } finally {
      this.pageLoading = false;
    }
  }

  /**
   * 查询可用供应商数据
   */
  private async initSuppliers(): Promise<void> {
    try {
      this.suppliers = await supplierService.listEnable();
    } catch (error) {
      messageError(error);
    }
  }

  private async uploadDetailFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      const minioFile: MinIOFile = {
        name: `${dateFormat(new Date(), 'yyyyMMDDHHmmss')}_${requestOptions.file.name}`,
        stream: Buffer.from(await requestOptions.file.arrayBuffer())
      };
      await minioService.init();
      const filePath = `import/${this.productionOrderForm.projectId}/order`;
      await minioService.uploadByStream(minioFile, filePath);
      const path = `${filePath}/${minioFile.name}`;
      this.productionOrderForm.detailFile = path;
    } catch (error) {
      messageError(error);
    }
  }

  private async uploadProductionFile(requestOptions: HttpRequestOptions): Promise<void> {
    try {
      const minioFile: MinIOFile = {
        name: `${dateFormat(new Date(), 'yyyyMMDDHHmmss')}_${requestOptions.file.name}`,
        stream: Buffer.from(await requestOptions.file.arrayBuffer())
      };
      await minioService.init();
      const filePath = `import/${this.productionOrderForm.projectId}/order`;
      await minioService.uploadByStream(minioFile, filePath);
      const path = `${filePath}/${minioFile.name}`;
      this.productionOrderForm.makeFile = path;
    } catch (error) {
      messageError(error);
    }
  }

  private async submit(submitWay: string): Promise<void> {
    try {
      this.pageLoading = true;
      const formValidateRes = await (this.$refs.productionOrderForm as ElForm).validate();
      if (!formValidateRes) {
        return;
      }
      if (!this.productionOrderForm.id) {
        submitWay === 'submit'
          ? await productionOrderService.post(this.productionOrderForm as ProductionOrderResource)
          : await productionOrderService.postAndSend(this.productionOrderForm as ProductionOrderResource);
      } else {
        await productionOrderService.put(this.productionOrderForm as ProductionOrderResource);
      }
      this.addSuccess = true;
    } catch (error) {
      if (error) {
        messageErrors(error);
      }
    } finally {
      this.pageLoading = false;
    }
  }
}
